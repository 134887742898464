import { ErrorAlert } from "components/ErrorAlert";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { SignUpDTO } from "types/signUp.type";
import { PasswordInput } from "./input/PasswordInput";
import { passwordValidator } from "helpers/validation";
import { FlowerHubLogo } from "styles/Icons";
import { ResetPasswordRequestDTO } from "../api/user/user.dto";

interface SignUpButton {
  loading: string;
  default: string;
}

interface Props {
  header?: string;
  buttonText?: SignUpButton;
  installerTerms?: boolean;
  register: UseFormRegister<SignUpDTO | ResetPasswordRequestDTO>;
  errors: Partial<FieldErrorsImpl<SignUpDTO | ResetPasswordRequestDTO>>;
  onSubmit: () => void;
  isLoading: boolean;
  isError: boolean;
  isPasswordValid: boolean;
}

export const SignUp = ({
  header = "Välj ett lösenord för att skapa ditt konto",
  buttonText = { loading: "Creating account...", default: "Create account" },
  installerTerms = false,
  register,
  errors,
  onSubmit,
  isLoading,
  isError,
  isPasswordValid,
}: Props) => {
  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-16 w-auto"
            src={FlowerHubLogo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-flowerblue-700">
            {header}
          </h2>
        </div>

        <div className="mt-8 mx-2 sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <PasswordInput
                register={register}
                name="password"
                label="Password"
                registerOptions={passwordValidator}
                errorMessage={errors.password?.message}
              />
              <div className="flex flex-col mt-3 mb-7">
                <p className="text-sm text-gray-400 mb-1">
                  Password requirements
                </p>
                <div className="flex items-center">
                  {isPasswordValid ? (
                    <>
                      <CheckCircleIcon className="h-5 w-5 mr-1 text-flowerSky-500" />
                      <p className="text-sm text-gray-500">
                        At least 10 characters
                      </p>
                    </>
                  ) : (
                    <>
                      <ExclamationCircleIcon className="h-5 w-5 mr-1 text-gray-400" />
                      <p className="text-sm text-gray-400">
                        At least 10 characters
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div>
                {installerTerms && (
                  <div className="text-sm  text-gray-600 font-medium mt-3 mb-2">
                    Genom att skapa ett konto accepterar du{" "}
                    <a
                      href="https://flowerhub.se/anvandarvillkor"
                      className="text-sm font-medium text-flowerSky-600 hover:text-flowerSky-500"
                    >
                      användarvillkoren
                    </a>
                  </div>
                )}

                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-flowerSky-500 disabled:bg-gray-400 text-base font-medium text-white hover:bg-flowerSky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowerSky-300 sm:col-start-2 sm:text-sm"
                  onClick={onSubmit}
                  disabled={!isPasswordValid}
                >
                  {isLoading && (
                    <svg
                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}

                  {isLoading ? buttonText.loading : buttonText.default}
                </button>
              </div>
              {isError && (
                <div className="mt-6">
                  <ErrorAlert text="Your link is invalid or has expired. Ask your admin to send you a new invitation." />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
