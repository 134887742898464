import { Item, Label, List, Value } from "components/descriptionList";
import { Shadow } from "components/Shadow";
import { FC } from "react";
import { Badge } from "components/tags/Badge";
import { useTranslation } from "react-i18next";
import { EditInstallerCanInviteAssetOwner } from "./EditInstallerCanInviteAssetOwner";
import { EditInstaller } from "./EditInstaller";
import { Role } from "enums/role.enum";
import { GetInstallerResponseDTO } from "../../api/installer/installer.dto";
import { Address } from "../Address";
import { isDistributor, isFlower } from "../../helpers/role";

interface Props {
  installer: GetInstallerResponseDTO;
  userRole: Role;
}

export const InstallerAccountDetails: FC<Props> = ({ installer, userRole }) => {
  const { t } = useTranslation();

  return (
    <>
      <Shadow>
        <List>
          {(isFlower(userRole) || isDistributor(userRole)) && (
            <Item>
              <Label>{t("installer.thisInstallerCanInviteAssetOwners")}</Label>
              <Value>
                <div className="flex flex-row justify-between">
                  {installer.canInviteAssetOwners ? (
                    <Badge text={t("installer.yes")} color="green" size="big" />
                  ) : (
                    <Badge text={t("installer.no")} color="red" size="big" />
                  )}
                  <EditInstallerCanInviteAssetOwner installer={installer} />
                </div>
              </Value>
            </Item>
          )}
          <Item>
            <Label>{t("installer.Company name")}</Label>
            <Value>{installer.name}</Value>
          </Item>
          <Item>
            <Label>{t("installer.Organization number")}</Label>
            <Value>{installer.organizationNumber}</Value>
          </Item>
          <Item>
            <Label>{t(`assetOwnerAccountDetails.Address`)}</Label>
            <Value>
              <Address address={installer.address} />
            </Value>
          </Item>
          <Item>
            <Label>{t("installer.Bankgiro / postgiro")}</Label>
            <Value>{installer.giroNumber}</Value>
          </Item>
        </List>
      </Shadow>

      {[Role.Flower, Role.InstallerAdmin].includes(userRole) && (
        <EditInstaller installer={installer} />
      )}
    </>
  );
};
