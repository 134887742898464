import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNotification } from "hooks/useNotification";
import { InstallerQueryKeys } from "api/installer/installer.endpoints";
import { useResendInvitation } from "api/installerInvitation/installerInvitation.endpoints";
import SecondaryButton from "components/buttons/SecondaryButton";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

interface Props {
  installerId: number;
  oldInvitationId: number;
}

export const ResentInstallerInvitation: FC<Props> = ({
  installerId,
  oldInvitationId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notification = useNotification();

  // api
  const { mutate: resendInvitation } = useResendInvitation({
    onSuccess: () => {
      queryClient.invalidateQueries([InstallerQueryKeys.GetUsers, installerId]);
      notification.info(`Invitation resent`);
    },
  });

  // submit
  const onResendInvitation = (inviteId: number) => resendInvitation(inviteId);

  return (
    <SecondaryButton
      size="small"
      onClick={() => onResendInvitation(oldInvitationId)}
    >
      <ArrowPathIcon className="h-5 w-5 mr-1" />
      {t(`installer.ResentInvitation`)}{" "}
    </SecondaryButton>
  );
};
