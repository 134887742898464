import { useUpdatePrequalificationGroup } from "api/flowerSettings/flowerSettings.endpoints";
import Modal from "components/Modal";
import { FC, useEffect, useState } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  PrequalificationGroupDTO,
  CreatePrequalificationGroupDTO,
} from "types/prequalificationGroup.type";
import { PrequalificationGroupForm } from "./PrequalificationGroupForm";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

interface Props {
  prequalificationGroup: PrequalificationGroupDTO;
}

export const EditPrequalificationGroup: FC<Props> = ({
  prequalificationGroup,
}) => {
  const { t } = useTranslation();

  // state
  const [
    isEditPrequalificationGroupModalOpen,
    setPrequalificationGroupModalOpen,
  ] = useState<boolean>(false);

  // api
  const {
    mutate: updatePrequalificationGroup,
    isLoading: isUpdatingPrequalificationGroup,
    isSuccess: isUpdatePrequalificationGroupSuccess,
  } = useUpdatePrequalificationGroup();

  // form
  const {
    register: registerUpdatePrequalificationGroup,
    handleSubmit: handleUpdatePrequalificationGroupSubmit,
    reset: resetUpdatePrequalificationGroup,
    formState: { errors: updatePrequalificationGroupErrors },
  } = useForm<PrequalificationGroupDTO>();

  // Populate or clear the form
  useEffect(() => {
    if (isEditPrequalificationGroupModalOpen) {
      resetUpdatePrequalificationGroup({ ...prequalificationGroup });
    }
  }, [isEditPrequalificationGroupModalOpen]);

  // submit form
  const onUpdatePrequalificationGroupSubmit =
    handleUpdatePrequalificationGroupSubmit(
      (data: PrequalificationGroupDTO) => {
        updatePrequalificationGroup({
          ...data,
          brp: +(data.brp ?? -1),
        });
      },
    );

  // close modal
  const onUpdatePrequalificationGroupModalClose = () => {
    setPrequalificationGroupModalOpen(false);
    setTimeout(() => resetUpdatePrequalificationGroup(), 300);
  };

  // trigger on success
  useEffect(() => {
    if (isUpdatePrequalificationGroupSuccess) {
      onUpdatePrequalificationGroupModalClose();
    }
  }, [isUpdatePrequalificationGroupSuccess]);

  return (
    <>
      <PencilSquareIcon
        className="h-5 w-5 ml-4 text-gray-700 hover:text-flowerPeach-900 cursor-pointer"
        onClick={() => setPrequalificationGroupModalOpen(true)}
      />

      <Modal
        isOpen={!!isEditPrequalificationGroupModalOpen}
        rightButtonActionHandler={onUpdatePrequalificationGroupSubmit}
        onClose={onUpdatePrequalificationGroupModalClose}
        leftButtonAction={onUpdatePrequalificationGroupModalClose}
        leftButtonText={t(`close`)}
        title={t(`prequalificationGroup.Update prequalification group`)}
        isLoading={isUpdatingPrequalificationGroup}
        rightButtonText={t(`SaveButton`)}
      >
        <PrequalificationGroupForm
          register={
            registerUpdatePrequalificationGroup as UseFormRegister<
              CreatePrequalificationGroupDTO | PrequalificationGroupDTO
            >
          }
          errors={updatePrequalificationGroupErrors}
        />
      </Modal>
    </>
  );
};
