import Dropdown from "components/input/Dropdown";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/input/TextInput";
import { InverterType } from "enums/inverterType.enum";
import { positiveNumberValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { INVERTER_TYPES } from "../installationFlow/installFlowerHub.helpers";
import { UpdateAssetRequestDTO } from "api/asset/asset.dto";
import { useAssetModels } from "api/assetModel/assetModel.endpoints";
import CustomPlaceholder from "components/CustomPlaceholder";
import { useTranslation } from "react-i18next";
import { Battery0Icon } from "@heroicons/react/24/outline";
import { fuses } from "components/installationFlow/bessTypes";

interface Props {
  register: UseFormRegister<UpdateAssetRequestDTO>;
  errors?: Partial<FieldErrorsImpl<UpdateAssetRequestDTO>>;
}
export const EditAssetForm: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();

  const { data: assetModels, isLoading, isError } = useAssetModels();

  if (isLoading || !assetModels || isError) {
    return (
      <CustomPlaceholder
        isError={isError}
        isLoading={isLoading}
        isEmpty={!isLoading && !isError && !assetModels}
        icon={<Battery0Icon />}
        errorMessage={t(`batteryOverview.UnableToFetchTheBattery`)}
      />
    );
  }

  return (
    <ModalContentWrapper>
      <Dropdown
        items={[
          { label: t("batteryOverview.PickAFuseSize"), value: 0 },
          ...fuses.map((fuse) => ({
            label: "" + fuse.ampere,
            value: fuse.ampere,
          })),
        ]}
        name="fuseSize"
        label={t("batteryOverview.MainFuseSize")}
        register={register}
        validation={{
          valueAsNumber: true,
          // check this validation
          validate: (value: number) =>
            +value !== -1 || "Select one from the list",
        }}
        errorMessage={errors?.fuseSize?.message}
      />
      <Dropdown
        items={[
          { label: t("batteryOverview.PickAModel"), value: 0 },
          ...assetModels.map((am) => ({
            label: `${am.manufacturer} ${am.name}`,
            value: am.id,
          })),
        ]}
        name="assetModelId"
        label={t("batteryOverview.Asset model")}
        register={register}
        validation={{
          validate: (value: string) =>
            +value !== -1 || "Select one from the list",
        }}
        errorMessage={errors?.assetModelId?.message}
      />
      <TextInput
        label={t("batteryOverview.BESS power capacity")}
        name="bessPowerCapacity"
        register={register}
        appendix="kW"
        size="third"
        registerOptions={positiveNumberValidator}
        type="number"
        errorMessage={errors?.bessPowerCapacity?.message}
      />
      <TextInput
        label={t("batteryOverview.Capacity")}
        name="bessCapacity"
        register={register}
        appendix="kWh"
        size="third"
        registerOptions={positiveNumberValidator}
        type="number"
        errorMessage={errors?.bessCapacity?.message}
      />
      <Dropdown
        items={INVERTER_TYPES}
        label={t("batteryOverview.InverterType")}
        name="inverterType"
        register={register}
        validation={{
          validate: (value: string) =>
            +value !== InverterType.Undefined || "Select one from the list",
        }}
        errorMessage={errors?.inverterType?.message}
      />
      <TextInput
        label={t("batteryOverview.InverterPowerCapacity")}
        name="inverterPowerCapacity"
        register={register}
        appendix="kW"
        size="third"
        errorMessage={errors?.inverterPowerCapacity?.message}
        registerOptions={positiveNumberValidator}
        type="number"
      />
    </ModalContentWrapper>
  );
};
