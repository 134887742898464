import { ModalContentWrapper } from "components/ModalContentWrapper";
import { useTranslation } from "react-i18next";

export const DeleteDistributorConfirmation = () => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <div className="mt-2">
        <p className="text-sm text-gray-500">
          {t(
            `distributor.Are you sure that you want to delete this distributor?`,
          )}
        </p>
      </div>
    </ModalContentWrapper>
  );
};
