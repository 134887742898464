import { Role } from "enums/role.enum";
import { AddressDTO } from "../shared/address.dto";
import { DistributorInvitationDTO } from "../distributorInvitation/distributorInvitation.dto";

export type GetDistributorItemResponseDTO = {
  id: number;
  name: string;
  organizationNumber: string;
  city?: string;
};

export type GetLatestInstallersItemResponseDTO = {
  installerId: number;
  name: string;
  organization: string;
  role: Role;
  createdAt: Date;
};

export type DistributorUserDTO = {
  id: number;
  name: string;
  email: string;
  role: Role;
};

export type GetDistributorUsersResponseDTO = {
  users?: DistributorUserDTO[];
  invitations?: DistributorInvitationDTO[];
};

export type GetDistributorResponseDTO = {
  id: number;
  name: string;
  organizationNumber: string;
  address: AddressDTO;
  giroNumber: string;
};

export type CreateDistributorRequestDTO = {
  name: string;
  organizationNumber: string;
  firstUserEmail: string;
};

export type CreateDistributorResponseDTO = {
  id: number;
  name: string;
};

export const getEmptyCreateDistributorDTO =
  (): CreateDistributorRequestDTO => ({
    name: "",
    organizationNumber: "",
    firstUserEmail: "",
  });

export type UpdateDistributorRequestDTO = {
  name: string;
  organizationNumber: string;
  address: AddressDTO;
  giroNumber: string;
};

export type UpdateDistributorResponseDTO = {
  id: number;
  name: string;
};

export type DeleteInvitationDTO = {
  distributorId: number;
  invitationId: number;
};
