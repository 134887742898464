import { Item, Label, List, Value } from "components/descriptionList";
import { Shadow } from "components/Shadow";
import TabContainer from "components/tab/TabContainer";
import { Role } from "enums/role.enum";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { EditDistributor } from "./EditDistributor";
import { GetDistributorResponseDTO } from "../../api/distributor/distributor.dto";
import { Address } from "../Address";

interface Props {
  distributor: GetDistributorResponseDTO;
  userRole: Role;
}

export const DistributorAccountDetails: FC<Props> = ({
  distributor,
  userRole,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TabContainer>
        <Shadow>
          <List>
            <Item>
              <Label>{t(`distributor.Company name`)}</Label>
              <Value>{distributor.name}</Value>
            </Item>
            <Item>
              <Label>{t(`distributor.Organization number`)}</Label>
              <Value>{distributor.organizationNumber}</Value>
            </Item>
            <Item>
              <Label>{t(`assetOwnerAccountDetails.Address`)}</Label>
              <Value>
                <Address address={distributor.address} />
              </Value>
            </Item>
            <Item>
              <Label>{t(`distributor.Bankgiro / postgiro`)}</Label>
              <Value>{distributor.giroNumber}</Value>
            </Item>
          </List>
        </Shadow>
      </TabContainer>

      {[Role.Flower, Role.DistributorAdmin].includes(userRole) && (
        <EditDistributor distributor={distributor} />
      )}
    </>
  );
};
