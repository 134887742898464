import { FC } from "react";
import { FlowerHubProgress } from "./flowerHubProgress/FlowerHubProgress";
import { FlowerHubLogs } from "./flowerHubLogs/FlowerHubLogs";
import { SystemManagerLogs } from "./flowerHubSystemManager/SystemManagerLogs";
import { useTranslation } from "react-i18next";
import CustomPlaceholder from "components/CustomPlaceholder";
import { ServerIcon } from "@heroicons/react/24/outline";
import userStore, { getUserRole } from "store/user.store";
import { FlowerHubSystemManager } from "./flowerHubSystemManager/FlowerHubSystemManager";
import { FlowerHubStatisticsFlowerVersion } from "./flowerHubStatistics/FlowerHubStatisticsFlowerVersion";
import { FlowerHubStatistics } from "./flowerHubStatistics/FlowerHubStatistics";
import { FlowerHubComponents } from "components/flowerHub/FlowerHubComponents";
import { InstallFlowerHub } from "components/installationFlow/InstallFlowerhub";
import { isFlower, isInstaller } from "../../helpers/role";

interface Props {
  assetOwnerId: number;
  assetId?: number;
  serialNumber?: string;
  hasSystemManager: boolean;
}

export const FlowerHubDetails: FC<Props> = ({
  assetOwnerId,
  assetId,
  serialNumber,
  hasSystemManager = false,
}) => {
  const { t } = useTranslation();
  const userRole = userStore(getUserRole);

  if (!assetId || !serialNumber) {
    return (
      <div className="flex-col my-8">
        <CustomPlaceholder
          isError={false}
          isLoading={false}
          isEmpty={true}
          icon={<ServerIcon />}
          errorMessage=""
          emptyDataMessage={t(`flowerHub.NoFlowerHub`)}
        />
        {isInstaller(userRole) && (
          <InstallFlowerHub assetOwnerId={assetOwnerId} />
        )}
      </div>
    );
  }

  return (
    <div>
      {isFlower(userRole) ? (
        <FlowerHubStatisticsFlowerVersion assetId={assetId} />
      ) : (
        <FlowerHubStatistics
          serialNumber={serialNumber}
          assetId={assetId}
          hasSystemManager={hasSystemManager}
        />
      )}

      <FlowerHubProgress serialNumber={serialNumber} />

      {isFlower(userRole) && (
        <FlowerHubComponents serialNumber={serialNumber} />
      )}

      {isFlower(userRole) && <FlowerHubSystemManager assetId={assetId} />}

      <div className="grid grid-cols-1 gap-0 lg:grid-cols-2 lg:gap-8">
        <FlowerHubLogs assetId={assetId} serialNumber={serialNumber} />
        {hasSystemManager && <SystemManagerLogs assetId={assetId} />}
      </div>
    </div>
  );
};
