import { Role } from "enums/role.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { BreadCrumb } from "store/menu.store";
import { getDistributorListBreadcrumb } from "../pages/app/distributorList/DistributorListPage";
import { getDistributorBreadcrumb } from "../pages/app/distributor/DistributorPage";
import { getInstallerListBreadcrumb } from "../pages/app/installerList/InstallerListPage";
import { GetInstallerResponseDTO } from "../api/installer/installer.dto";

export const getBreadCrumbs = (
  userRole: Role,
  installer: GetInstallerResponseDTO,
) => {
  switch (userRole) {
    case Role.Flower:
      return [
        getDistributorListBreadcrumb(),
        getDistributorBreadcrumb({
          id: installer.distributor.id,
          name: installer.distributor.name ?? "",
        }),
        getInstallerBreadcrumb(
          installer.name,
          installer.id,
          installer.distributor.id,
          Role.Flower,
        ),
      ];
    case Role.DistributorAdmin:
    case Role.DistributorUser:
      return [
        getInstallerListBreadcrumb(),
        getInstallerBreadcrumb(
          installer.name,
          installer.id,
          installer.distributor.id,
          userRole,
        ),
      ];
    default:
      throw new Error(
        `InstallerPage.getBreadCrumbs is missing implementation for role ${userRole}`,
      );
  }
};

export const getInstallerPageRoute = (
  userRole: Role,
  installerId: number,
  distributorId?: number,
) => {
  switch (userRole) {
    case Role.Flower:
      return `${RouteUrl.App}/${RouteUrl.Distributors}/${distributorId}/${installerId}`;
    case Role.DistributorAdmin:
    case Role.DistributorUser:
      return `${RouteUrl.App}/${RouteUrl.Installers}/${installerId}`;
    default:
      throw Error(`Role ${userRole} should not access getInstallerPageRoute`);
  }
};

export const getInstallerBreadcrumb = (
  installerName: string,
  installerId: number,
  distributorId: number,
  userRole: Role,
): BreadCrumb => {
  return {
    label: installerName,
    to: getInstallerPageRoute(userRole, installerId, distributorId),
    iconType: "installer",
  };
};
