import { AddressDTO } from "api/shared/address.dto";
import { InstallerStatus } from "../../enums/installerStatus.enum";
import { Role } from "../../enums/role.enum";
import { InstallerInvitationDTO } from "../installerInvitation/installerInvitation.dto";

export type InstallerContactDTO = {
  id: number;
  name: string;
  address: AddressDTO;
};

export type GetInstallerItemResponseDTO = {
  id: number;
  name: string;
  organizationNumber: string;
  city?: string;
  distributorId: number;
  canInviteAssetOwners: boolean;
  status: InstallerStatus;
};

type DistributorDTO = {
  id: number;
  name: string;
};

export type GetInstallerResponseDTO = {
  id: number;
  name: string;
  organizationNumber: string;
  distributor: DistributorDTO;
  address: AddressDTO;
  giroNumber: string;
  canInviteAssetOwners: boolean;
};

export type InstallerUserDTO = {
  id: number;
  name: string;
  email: string;
  role: Role;
};

export type GetInstallerUsersResponseDTO = {
  users?: InstallerUserDTO[];
  invitations?: InstallerInvitationDTO[];
};

export type CreateInstallerRequestDTO = {
  distributorId: number;
  name: string;
  organizationNumber: string;
  firstUserEmail: string;
  canInviteAssetOwners: boolean;
};

export type CreateInstallerResponseDTO = {
  id: number;
  name: string;
};

export const getEmptyCreateInstallerDTO = (): CreateInstallerRequestDTO => ({
  distributorId: -1,
  name: "",
  organizationNumber: "",
  firstUserEmail: "",
  canInviteAssetOwners: true,
});

export type UpdateInstallerRequestDTO = {
  name: string;
  organizationNumber: string;
  address: AddressDTO;
  giroNumber: string;
};

export type UpdateInstallerCanInviteAssetOwnersRequestDTO = {
  canInviteAssetOwners: boolean;
};

export type UpdateInstallerResponseDTO = {
  id: number;
  name: string;
};
