import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import { GetAssetModelItemResponseDTO } from "api/assetModel/assetModel.dto";
import { useUpdateAssetModel } from "api/assetModel/assetModel.endpoints";
import { getEmptyAssetModelDTO } from "api/assetModel/assetModel.helper";
import { EditAssetModelForm } from "./EditAssetModelForm";
import { useTranslation } from "react-i18next";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

interface Props {
  assetModel: GetAssetModelItemResponseDTO;
}

export const EditAssetModel: FC<Props> = ({ assetModel }) => {
  const { t } = useTranslation();

  // state
  const [isEditAssetModelModalOpen, setIsEditAssetModelModalOpen] =
    useState<boolean>(false);

  // api
  const {
    mutate: updateAssetModel,
    isLoading: isUpdatingAssetModel,
    isSuccess: isUpdateAssetModelSuccess,
  } = useUpdateAssetModel();

  // form
  const {
    register: registerUpdateAssetModel,
    handleSubmit: handleUpdateAssetModelSubmit,
    reset: resetUpdateAssetModel,
    formState: { errors: assetModelErrors },
  } = useForm<GetAssetModelItemResponseDTO>();

  // submit
  const onUpdateAssetModelSubmit = handleUpdateAssetModelSubmit(
    (data: GetAssetModelItemResponseDTO) => updateAssetModel(data),
  );

  // modal close
  const onUpdateAssetModelModalClose = () => {
    setIsEditAssetModelModalOpen(false);
    setTimeout(() => resetUpdateAssetModel(getEmptyAssetModelDTO()), 300);
  };

  // after submit success
  useEffect(() => {
    if (isUpdateAssetModelSuccess) {
      onUpdateAssetModelModalClose();
    }
  }, [isUpdateAssetModelSuccess]);

  // reset form on isEditAssetModelModalOpen change
  useEffect(() => {
    if (isEditAssetModelModalOpen) {
      resetUpdateAssetModel({ ...assetModel });
    }
  }, [isEditAssetModelModalOpen]);

  const renderEditAssetModelButton = () => {
    return (
      <PencilSquareIcon
        className="h-5 w-5 text-gray-700 hover:text-flowerPeach-900 cursor-pointer"
        onClick={() => setIsEditAssetModelModalOpen(true)}
      />
    );
  };

  return (
    <>
      {renderEditAssetModelButton()}

      <Modal
        isOpen={!!isEditAssetModelModalOpen}
        onClose={onUpdateAssetModelModalClose}
        leftButtonAction={onUpdateAssetModelModalClose}
        leftButtonText={t(`close`)}
        title={t(`assetModel.Edit asset model`)}
        rightButtonActionHandler={onUpdateAssetModelSubmit}
        isLoading={isUpdatingAssetModel}
        rightButtonText={t(`SaveButton`)}
      >
        <EditAssetModelForm
          register={registerUpdateAssetModel}
          errors={assetModelErrors}
        />
      </Modal>
    </>
  );
};
