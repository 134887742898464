import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { InstallerQueryKeys } from "api/installer/installer.endpoints";
import { useQueryClient } from "react-query";
import Modal from "components/Modal";
import { DeleteInstallerConfirmation } from "./DeleteInstallerConfirmation";
import DangerButton from "components/buttons/DangerButton";
import { useDeactivateUser } from "api/user/user.endpoints";

interface Props {
  installerId: number;
  userId: number;
}

export const DeleteInstallerUser: FC<Props> = ({ installerId, userId }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isDeleteInstallerModalOpen, setIsInstallerModalOpen] = useState(false);

  // api
  const { mutate: deactivateUser } = useDeactivateUser({
    onSuccess: () =>
      queryClient.invalidateQueries([InstallerQueryKeys.GetUsers, installerId]),
  });
  // submit
  const onDeactivateUser = () => deactivateUser(userId);

  // modal close
  const onDeleteInstallerModalClose = () => {
    setIsInstallerModalOpen(false);
  };

  return (
    <>
      <DangerButton size="small" onClick={() => setIsInstallerModalOpen(true)}>
        {t(`installer.Deactivate user`)}
      </DangerButton>

      <Modal
        title={t(`installer.Delete installer`)}
        leftButtonAction={onDeleteInstallerModalClose}
        leftButtonText={t(`close`)}
        rightButtonActionHandler={() => onDeactivateUser()}
        isOpen={isDeleteInstallerModalOpen}
        onClose={onDeleteInstallerModalClose}
        loadingText={t(`installer.Deleting user`)}
      >
        <DeleteInstallerConfirmation />
      </Modal>
    </>
  );
};
