import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "../buttons/SecondaryButton";
import { useCreateInstaller } from "api/installer/installer.endpoints";
import {
  CreateInstallerRequestDTO,
  getEmptyCreateInstallerDTO,
} from "api/installer/installer.dto";
import { AddInstallerForm } from "./AddInstallerForm";
import { useTranslation } from "react-i18next";

interface Props {
  distributorId: number;
  isCentered?: boolean;
}

export const AddInstaller: FC<Props> = ({
  distributorId,
  isCentered = false,
}) => {
  const { t } = useTranslation();

  // state
  const [isAddInstallerModalOpen, setIsAddInstallerModalOpen] =
    useState<boolean>(false);

  // api
  const {
    mutate: createInstaller,
    isLoading: isCreating,
    isSuccess,
  } = useCreateInstaller();

  // form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateInstallerRequestDTO>({
    defaultValues: getEmptyCreateInstallerDTO(),
  });

  // submit
  const onSubmit = handleSubmit((data: CreateInstallerRequestDTO) =>
    createInstaller({ ...data, distributorId }),
  );

  // modal close
  const closeModal = () => {
    setIsAddInstallerModalOpen(false);
    setTimeout(() => reset(), 300);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  const renderAddInstallerButton = () => {
    return (
      <div
        className={`pb-2 flex ${isCentered ? "justify-center" : "justify-end"}`}
      >
        <SecondaryButton onClick={() => setIsAddInstallerModalOpen(true)}>
          <UserPlusIcon className="h-5 w-5 mr-1" />
          {t(`installerList.Add installer`)}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <>
      {renderAddInstallerButton()}

      <Modal
        isOpen={isAddInstallerModalOpen}
        onClose={closeModal}
        title={t(`installerList.Add installer`)}
        rightButtonActionHandler={onSubmit}
        isLoading={isCreating}
        rightButtonText={t(`installerList.Send invitation`)}
      >
        <AddInstallerForm register={register} errors={errors} />
      </Modal>
    </>
  );
};
