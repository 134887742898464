//Server side enum for status progress
export enum FlowerHubProgressContext {
  Register = "Register",
  Initialize = "Initialize",
  Install = "Install",
  Deploy = "Deploy",
  Connect = "Connect",
  Communicate = "Communicate",
}

export enum FlowerHubProgressStage {
  Completed = "Completed",
  Current = "Current",
  Upcoming = "Upcoming",
}

export enum FlowerHubStatusMessageCode {
  //Flower hub installation and requirement related message codes
  NotReceivedSignalFromFlowerHub = "NotReceivedSignalFromFlowerHub",
  FlowerHubCertificateProvisioningStarting = "FlowerHubCertificateProvisioningStarting",
  FlowerHubCertificateProvisioningFailed = "FlowerHubCertificateProvisioningFailed",
  FlowerHubWaitingForCertificateProvisioning = "FlowerHubWaitingForCertificateProvisioning",

  // Green grass 'thing' related message codes
  WaitingToCreateIoTThing = "WaitingToCreateIoTThing",
  IoTThingCreationFailed = "IoTThingCreationFailed",

  // Green grass 'core device' related message codes
  WaitingToCreateCoreDevice = "WaitingToCreateCoreDevice",
  CoreDeviceCreationFailed = "CoreDeviceCreationFailed",

  // Green grass 'deployments' related message codes
  WaitingToStartDeployment = "WaitingToStartDeployment",
  GreengrassDeploymentSucceeded = "GreengrassDeploymentSucceeded", // Won't return to client
  GreengrassDeploymentInProgress = "GreengrassDeploymentInProgress",
  GreengrassDeploymentFailed = "GreengrassDeploymentFailed",
  UnableToFindGreengrassDeployment = "UnableToFindGreengrassDeployment",
  PendingDeploymentDueToMissingBiddingRegion = "PendingDeploymentDueToMissingBiddingRegion",
  GreengrassDeploymentUnknownStatus = "GreengrassDeploymentUnknownStatus",

  // Green grass 'components' related message codes
  ComponentsAreRunning = "ComponentsAreRunning", // Won't return to client
  OtherComponentErrored = "OtherComponentErrored",
  M2MComponentErrored = "M2MComponentErrored",
  UnableToFindGreengrassComponents = "UnableToFindGreengrassComponents",

  // Failure manager related message codes
  CannotFindDongle = "CannotFindDongle",
  ComponentsAreRunningWaitingForDongleFound = "ComponentsAreRunningWaitingForDongleFound",

  // All done and communicating
  ComponentsAreRunningAndCommunicating = "ComponentsAreRunningAndCommunicating",
}

export enum FlowerHubStatus {
  Communicating = "Communicating",
  WaitingForCommunicating = "Waiting for communicating",
  CommunicationError = "Communication error",
  DeviceError = "Device error",
  DeploymentError = "Deployment Error",
  DeploymentInProgress = "Deployment in progress",
  DeploymentPending = "Deployment pending",
  InstallationFailed = "Installation failed",
  InstallationInProgress = "Installation in progress",
  Registering = "Registering",
  RegistrationFailed = "Registration failed",
  Unknown = "Unknown",
}
