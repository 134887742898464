import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import Modal from "components/Modal";
import { DeleteDistributorConfirmation } from "./DeleteDistributorConfirmation";
import DangerButton from "components/buttons/DangerButton";
import { useDeactivateUser } from "api/user/user.endpoints";
import { DistributorQueryKeys } from "api/distributor/distributor.endpoints";

interface Props {
  distributorId: number;
  userId: number;
}

export const DeleteDistributorUser: FC<Props> = ({ distributorId, userId }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isDeleteDistributorModalOpen, setIsDistributorModalOpen] =
    useState(false);

  // api
  const { mutate: deactivateUser, isSuccess } = useDeactivateUser({
    onSuccess: () =>
      queryClient.invalidateQueries([
        DistributorQueryKeys.GetUsers,
        distributorId,
      ]),
  });

  // submit
  const onDeactivateUser = () => deactivateUser(userId);

  // modal close
  const onDeleteDistributorModalClose = () => {
    setIsDistributorModalOpen(false);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      onDeleteDistributorModalClose();
    }
  }, [isSuccess]);
  return (
    <>
      <DangerButton
        size="small"
        onClick={() => setIsDistributorModalOpen(true)}
      >
        {t(`distributor.Deactivate user`)}
      </DangerButton>

      <Modal
        title={t(`distributor.Delete distributor`)}
        leftButtonAction={onDeleteDistributorModalClose}
        leftButtonText={t(`close`)}
        rightButtonActionHandler={() => onDeactivateUser()}
        isOpen={isDeleteDistributorModalOpen}
        onClose={onDeleteDistributorModalClose}
        loadingText={t(`distributor.Deleting distributor`)}
      >
        <DeleteDistributorConfirmation />
      </Modal>
    </>
  );
};
