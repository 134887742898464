import { FC } from "react";
import { OpacityTransition } from "components/animations/SnapUp";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { StepHeader } from "components/installationFlow/steps/StepHeader";
import { useTranslation } from "react-i18next";
import { AssetInformation } from "./AssetInformationStep";
import { Fuse } from "../bessTypes";

type MetaDataConfirmationProps = {
  assetInformation: AssetInformation;
  assetSerialNumber?: string;
  fuse?: Fuse;
};
export const MetaDataConfirmationStep: FC<MetaDataConfirmationProps> = ({
  assetInformation,
  assetSerialNumber,
  fuse,
}) => {
  const { t } = useTranslation();
  return (
    <OpacityTransition>
      <StepHeader>
        {t("batteryOverview.Is the following information correct?")}
      </StepHeader>
      <div className="flex justify-center">
        <div className="text-base font-normal text-gray-600">
          <p className="mb-16 text-base text-gray-800">
            <ExclamationTriangleIcon
              className="h-8 w-8 text-yellow-400 inline mb-0.5"
              aria-hidden="true"
            />{" "}
            {t(
              "batteryOverview.After this step you cannot update the information",
            )}
          </p>
          <div className="flex justify-center border-solid border-2 border-gray-300">
            <div className="text-gray-700">
              <DataItem
                label={t("batteryOverview.Serial number")}
                value={assetSerialNumber}
              />
              <DataItem
                label={t("batteryOverview.FuseSize")}
                value={`${fuse?.ampere} A`}
              />
              <DataItem
                label={t("batteryOverview.InverterManufacturer")}
                value={assetInformation.manufacturer?.label}
              />
              <DataItem
                label={t("batteryOverview.Asset model")}
                value={assetInformation.assetModel?.label}
              />
              <DataItem
                label={t("batteryOverview.Battery energy capacity")}
                value={`${assetInformation.configuration?.ratedEnergy} kWh`}
              />
              <DataItem
                label={t("batteryOverview.Battery power capacity")}
                value={`${assetInformation.configuration?.ratedPower} kW`}
              />
              <DataItem
                label={t("batteryOverview.InverterPowerCapacity")}
                value={`${assetInformation.inverterPowerCapacity} kW`}
              />
            </div>
          </div>
        </div>
      </div>
    </OpacityTransition>
  );
};

interface DataItemProp {
  label: string;
  value?: string;
}

const DataItem = ({ label, value }: DataItemProp) => (
  <p className="mb-6">
    <span className="font-medium">{label}:</span> {value}
  </p>
);
