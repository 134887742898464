import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../axiosInstance";
import {
  ForgotPasswordRequestDTO,
  GetFlowerUsersResponseDTO,
  ResetPasswordRequestDTO,
  UpdateInstallerUserRequestDTO,
  UpdateInstallerUserResponseDTO,
} from "./user.dto";
import { InstallerQueryKeys } from "api/installer/installer.endpoints";
import { ErrorResponse } from "types/errorResponse.type";

const ENDPOINT = "user";

export enum UserQueryKeys {
  GetUsers = "user-get-users",
  GetFlowerUsers = "user-get-flower-users",
}

export const useFlowerUsers = () =>
  useQuery(
    [UserQueryKeys.GetFlowerUsers],
    async (): Promise<GetFlowerUsersResponseDTO> =>
      (await api.get(`${ENDPOINT}/flower`)).data,
    { initialData: { users: [], invitations: [] } },
  );

export const useForgotPassword = () => {
  return useMutation(
    async (payload: ForgotPasswordRequestDTO): Promise<void> =>
      await api.post(`${ENDPOINT}/forgot-password`, payload),
  );
};

export const useResetPassword = () => {
  const notification = useNotification();
  return useMutation(
    async (payload: ResetPasswordRequestDTO): Promise<void> =>
      await api.put(`${ENDPOINT}/reset-password`, payload),
    {
      onError: () => {
        notification.error();
      },
    },
  );
};

export const useUpdateInstallerUser = (installerUserId: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (
      payload: UpdateInstallerUserRequestDTO,
    ): Promise<UpdateInstallerUserResponseDTO> =>
      (await api.put(`${ENDPOINT}/${installerUserId}`, payload)).data,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          InstallerQueryKeys.GetUsers,
          data.installerId,
        ]);
        notification.info(`${data.name} updated`);
      },
      onError: (error: ErrorResponse) => {
        notification.error(error.response.data.message);
      },
    },
  );
};

export const useDeactivateUser = (settings = {}) => {
  const notification = useNotification();
  return useMutation(
    async (id: number): Promise<void> => await api.delete(`${ENDPOINT}/${id}`),
    {
      onError: () => {
        notification.error();
      },
      ...settings,
    },
  );
};
