import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/input/TextInput";
import { positiveNumberValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UpdateZavannSiteIdsRequestDTO } from "api/assetOwner/assetOwner.dto";

interface Props {
  register: UseFormRegister<UpdateZavannSiteIdsRequestDTO>;
  errors?: Partial<FieldErrorsImpl<UpdateZavannSiteIdsRequestDTO>>;
}
export const EditZavannSiteIdsForm: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t("editZavannSiteIdsForm.Consumption site id")}
        name="zavannNewAccountConsumptionSiteId"
        register={register}
        size="half"
        registerOptions={positiveNumberValidator}
        type="number"
        errorMessage={errors?.zavannNewAccountConsumptionSiteId?.message}
      />
      <TextInput
        label={t("editZavannSiteIdsForm.Production site id")}
        name="zavannNewAccountProductionSiteId"
        register={register}
        size="half"
        registerOptions={positiveNumberValidator}
        type="number"
        errorMessage={errors?.zavannNewAccountProductionSiteId?.message}
      />
    </ModalContentWrapper>
  );
};
