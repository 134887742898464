import { FC } from "react";
import { useUpdateInstallerCanInviteAssetOwners } from "api/installer/installer.endpoints";
import { LoadingToggle } from "components/input/LoadingToggle";
import { GetInstallerResponseDTO } from "../../api/installer/installer.dto";

interface Props {
  installer: GetInstallerResponseDTO;
}

export const EditInstallerCanInviteAssetOwner: FC<Props> = ({ installer }) => {
  const {
    mutate: updateCanInviteAssetOwners,
    isLoading: isUpdatingCanInviteAssetOwners,
  } = useUpdateInstallerCanInviteAssetOwners(installer.id);

  return (
    <LoadingToggle
      isLoading={isUpdatingCanInviteAssetOwners}
      onToggle={(newState) =>
        updateCanInviteAssetOwners({
          canInviteAssetOwners: newState,
        })
      }
      isChecked={installer.canInviteAssetOwners}
    />
  );
};
