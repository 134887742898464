import { Shadow } from "components/Shadow";
import { Table, TBody, TD, TH, THead, TR } from "components/table";

import { GetAssetModelItemResponseDTO } from "api/assetModel/assetModel.dto";
import { useAssetModels } from "api/assetModel/assetModel.endpoints";
import { EditAssetModel } from "./EditAssetModel";
import { DeleteAssetModel } from "./DeleteAssetModel";
import CustomPlaceholder from "components/CustomPlaceholder";
import { FolderPlusIcon } from "@heroicons/react/24/outline";
import { AddAssetModel } from "./AddAssetModel";
import { isEmptyArray } from "helpers/array";
import { useTranslation } from "react-i18next";
import { FlowerSymbolLogo } from "styles/Icons";

export const AssetModelList = () => {
  const { t } = useTranslation();

  const { data: assetModels, isLoading, isError } = useAssetModels();

  if (isLoading || isError || assetModels === undefined) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError || assetModels === undefined}
          isLoading={isLoading}
          isEmpty={false}
          icon={
            <img
              className={`h-auto w-28`}
              src={FlowerSymbolLogo}
              alt="Flower Hub"
            />
          }
          errorMessage={t(`assetModel.Error`)}
          loaderSize="page"
        />
      </div>
    );
  }

  if (isEmptyArray(assetModels)) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={isEmptyArray(assetModels)}
          icon={<FolderPlusIcon />}
          emptyDataMessage={t(`assetModel.Empty`)}
        />
        <AddAssetModel centered={true} />
      </div>
    );
  }

  return (
    <>
      <Shadow>
        <Table>
          <THead>
            <TR>
              <TH isFirstColumn>{t(`assetModel.Model`)}</TH>
              <TH>{t(`assetModel.Manufacturer`)}</TH>
              <TH>{t(`assetModel.Description`)}</TH>
              <TH>{t(`assetModel.Compensation`)}</TH>
              <TH>
                <></>
              </TH>
            </TR>
          </THead>
          <TBody>
            {assetModels?.map((assetModel: GetAssetModelItemResponseDTO) => (
              <TR key={assetModel.id}>
                <TD addLeftMargin>{assetModel.name}</TD>
                <TD>{assetModel.manufacturer}</TD>
                <TD>{assetModel.description}</TD>
                <TD>{assetModel.compensation}</TD>
                <TD>
                  <div className="flex justify-end pr-4">
                    <EditAssetModel assetModel={assetModel} />
                    <DeleteAssetModel assetModel={assetModel} />
                  </div>
                </TD>
              </TR>
            ))}
          </TBody>
        </Table>
      </Shadow>

      <AddAssetModel />
    </>
  );
};
