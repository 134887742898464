import { CreateFlowerInvitationRequestDTO } from "api/flowerInvitation/flowerInvitation.dto";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/input/TextInput";
import { emailValidator, noEmptyStringValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  register: UseFormRegister<CreateFlowerInvitationRequestDTO>;
  errors: Partial<FieldErrorsImpl<CreateFlowerInvitationRequestDTO>>;
}

export const AddFlowerUserForm: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t(`flowerUser.Name`)}
        name="name"
        register={register}
        errorMessage={errors?.name?.message}
        registerOptions={noEmptyStringValidator()}
      />
      <TextInput
        label={t(`flowerUser.Email`)}
        name="email"
        type="email"
        register={register}
        errorMessage={errors?.email?.message}
        registerOptions={emailValidator}
      />
    </ModalContentWrapper>
  );
};
