import {
  GetComponentStatusItemResponseDTO,
  GetFlowerHubProgressResponseDTO,
} from "./flowerHub.dto";
import { useQuery } from "react-query";
import api from "../axiosInstance";

const ENDPOINT = "/flower-hub";

export enum FlowerHubQueryKeys {
  GetProgress = "get-progress",
  GetComponents = "get-components",
}

export const useFlowerHubProgress = (serialNumber: string) => {
  return useQuery(
    [FlowerHubQueryKeys.GetProgress, serialNumber],
    async (): Promise<GetFlowerHubProgressResponseDTO> =>
      (await api.get(`${ENDPOINT}/progress/${serialNumber}`)).data,
    {
      refetchInterval: 60000, // Refetch every 60 seconds when component loads
    },
  );
};

export const useFlowerHubComponents = (serialNumber: string) => {
  return useQuery(
    [FlowerHubQueryKeys.GetComponents, serialNumber],
    async (): Promise<GetComponentStatusItemResponseDTO[]> =>
      (await api.get(`${ENDPOINT}/components/${serialNumber}`)).data,
  );
};
