import { useNotification } from "hooks/useNotification";
import { useMutation, useQueryClient } from "react-query";
import api from "../axiosInstance";
import { CreateFlowerInvitationRequestDTO } from "./flowerInvitation.dto";
import { SignupByCodeRequestDTO } from "../shared/signUpByCode.dto";

const ENDPOINT = "/flower-invitation";

export enum FlowerInvitationQueryKeys {
  GetAll = "flower-invitation-get-all",
}

export const useFlowerSignUp = () => {
  return useMutation(
    async (payload: SignupByCodeRequestDTO) =>
      (await api.post(`${ENDPOINT}/sign-up-by-code`, payload)).data,
  );
};

export const useCreateFlowerInvitation = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: CreateFlowerInvitationRequestDTO) =>
      await api.post(ENDPOINT, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([FlowerInvitationQueryKeys.GetAll]);
        notification.info("Invitation sent");
      },
      onError: () => {
        notification.error("Something went wrong, please try again");
      },
    },
  );
};

export const useDeleteFlowerInvitation = () => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (id: number) => await api.delete(`${ENDPOINT}/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([FlowerInvitationQueryKeys.GetAll]);
      },
      onError: () => {
        notification.error();
      },
    },
  );
};
