import api from "api/axiosInstance";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GetBillingAdjustmentStatisticsResponseDTO,
  GetQualifiedAssetOwnerItemResponseDTO,
} from "./bessCompensation.dto";

const ENDPOINT = "/bess-compensation";

export enum BessCompensationQueryKeys {
  GetBillingAdjustmentStatistics = "get-statistics",
  GetQualifiedAssetOwners = "get-qualified-asset-owners",
}

export const useBillingAdjustmentStatistics = () => {
  return useQuery(
    [BessCompensationQueryKeys.GetBillingAdjustmentStatistics],
    async (): Promise<GetBillingAdjustmentStatisticsResponseDTO> =>
      (await api.get(`${ENDPOINT}/billing-adjustment-statistics`)).data,
  );
};

export const useQualifiedAssetOwners = () => {
  return useQuery(
    [BessCompensationQueryKeys.GetQualifiedAssetOwners],
    async (): Promise<GetQualifiedAssetOwnerItemResponseDTO[]> =>
      (await api.get(`${ENDPOINT}`)).data,
  );
};

export const usePostBillingAdjustments = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (): Promise<void> =>
      (await api.post(`${ENDPOINT}/billing-adjustments`)).data,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          BessCompensationQueryKeys.GetQualifiedAssetOwners,
          BessCompensationQueryKeys.GetBillingAdjustmentStatistics,
        ]);
      },
    },
  );
};
