import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RouteUrl } from "routing/routeUrl.enum";
import { RouteLabel } from "routing/routeLabel.enum";
import { BreadCrumb, menuStore } from "store/menu.store";
import userStore, { getUserId, getUserRole } from "store/user.store";
import { useTranslation } from "react-i18next";
import { FlowerUsersTab } from "components/flowerUser/FlowerUsersTab";

const USERS_TAB = "users";

export const FlowerOrganizationPage = () => {
  const { t } = useTranslation();

  const userRole = userStore(getUserRole);
  const loggedInUserId = userStore(getUserId) || -1;

  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });
  const currentTab = searchParams.get("tab") || USERS_TAB;

  // breadcrumbs
  const { setBreadCrumbs } = menuStore();
  useEffect(() => {
    setBreadCrumbs(getBreadcrumbs());
  }, []);

  return (
    <TabPageWrapper
      tabs={[
        {
          param: USERS_TAB,
          label: t(`flowerUser.UsersTitle`),
          content: (
            <FlowerUsersTab userRole={userRole} userId={loggedInUserId} />
          ),
          isSelected: currentTab === USERS_TAB,
        },
      ]}
      setTab={setTab}
    />
  );
};

const getBreadcrumbs = (): BreadCrumb[] => [
  {
    label: RouteLabel.Organization,
    to: `${RouteUrl.App}/${RouteUrl.Organization}`,
  },
];
