import { LoginPage } from "../pages/login/LoginPage";
import { Navigate, Outlet } from "react-router-dom";
import { AppWrapper } from "../components/appWrapper/AppWrapper";
import { RouteUrl } from "./routeUrl.enum";
import { DistributorsPage } from "pages/app/distributorList/DistributorListPage";
import DistributorPage from "pages/app/distributor/DistributorPage";
import SignUpDistributorPage from "pages/sign-up/SignUpDistributorPage";
import InstallerPage from "pages/app/installer/InstallerPage";
import { InstallerListPage } from "pages/app/installerList/InstallerListPage";
import { AssetOwnerListPage } from "pages/app/assetOwnerList/AssetOwnerListPage";
import ForgotPasswordPage from "pages/forgot-password/ForgotPasswordPage";
import ResetPasswordPage from "pages/reset-password/ResetPasswordPage";
import SignUpInstallerPage from "pages/sign-up/SignUpInstallerPage";
import { SignUpFlowPage } from "pages/sign-up-flow/SignUpFlowPage";
import { Role } from "enums/role.enum";
import { SignUpFlowerPage } from "pages/sign-up/SignUpFlowerPage";
import { DashboardPage } from "pages/app/dashboard/DashboardPage";
import { FlowerOrganizationPage } from "pages/app/flowerOrganization/FlowerOrganizationPage";
import { FlowerSettingsPage } from "pages/app/flowerSettings/FlowerSettingsPage";
import { DistributorOrganizationPage } from "pages/app/distributor/DistributorOrganizationPage";
import { ProfilePage } from "pages/app/assetOwner/ProfilePage";
import { NotFoundPage } from "pages/app/notFound/NotFoundPage";
import { ManualActionPage } from "pages/app/manualAction/ManualActionPage";
import { AssetListPage } from "pages/app/assetList/AssetListPage";
import { Centered } from "components/Centered";
import { Maintenance } from "pages/maintenance/maintenance";
import { SystemNotification } from "api/system-notification/systemNotification.type";
import { InvoicesPage } from "pages/app/assetOwner/InvoicesPage";
import { ConsumptionAndRevenuePage } from "pages/app/assetOwner/ConsumptionAndRevenuePage";
import { BatteryPage } from "pages/app/assetOwner/BatteryPage";
import { AssetOwnerDashboardPage } from "pages/app/assetOwner/AssetOwnerDashboardPage";
import AssetOwnerPage from "pages/app/assetOwnerPage/AssetOwnerPage";
import BillingAdjustmentPage from "pages/app/billing-adjustment/BillingAdjustmentPage";
import { InstallerOrganizationPage } from "pages/app/installer/InstallerOrganizationPage";
import {
  isAssetOwner,
  isDistributor,
  isFlower,
  isGuest,
  isInstaller,
} from "../helpers/role";

export const maintenanceRoute = (maintenance?: SystemNotification) => [
  {
    path: RouteUrl.AllRoutes,
    element: <Navigate to={RouteUrl.Home} />,
  },
  {
    path: RouteUrl.Home,
    element: (
      <Centered>
        <Maintenance maintenance={maintenance} />
      </Centered>
    ),
  },
];

const routes = (isLoggedIn: boolean, userRole: Role) => [
  {
    path: RouteUrl.App + "/*",
    element: isLoggedIn ? (
      <AppWrapper>
        <Outlet />
      </AppWrapper>
    ) : (
      <Navigate to={RouteUrl.Login} />
    ),
    children: [
      ...getAppRoutes(userRole),
      { path: "*", element: <NotFoundPage /> },
    ],
  },
  {
    path: RouteUrl.Login,
    element: isLoggedIn ? <Navigate to={RouteUrl.App} /> : <LoginPage />,
  },
  {
    path: `/${RouteUrl.SignUpFlower}`,
    element: isLoggedIn ? <Navigate to={RouteUrl.App} /> : <SignUpFlowerPage />,
  },
  {
    path: `/${RouteUrl.SignUpDistributor}`,
    element: isLoggedIn ? (
      <Navigate to={RouteUrl.App} />
    ) : (
      <SignUpDistributorPage />
    ),
  },
  {
    path: `/${RouteUrl.SignUpInstaller}`,
    element: isLoggedIn ? (
      <Navigate to={RouteUrl.App} />
    ) : (
      <SignUpInstallerPage />
    ),
  },
  {
    path: `/${RouteUrl.SignUpAssetOwner}`, // This should be merged with SIGN_UP_FLOW
    element: isLoggedIn ? <Navigate to={RouteUrl.App} /> : <SignUpFlowPage />,
  },
  {
    path: `/${RouteUrl.ForgotPassword}`,
    element: isLoggedIn ? (
      <Navigate to={RouteUrl.App} />
    ) : (
      <ForgotPasswordPage />
    ),
  },
  {
    path: `/${RouteUrl.ResetPassword}`,
    element: isLoggedIn ? (
      <Navigate to={RouteUrl.App} />
    ) : (
      <ResetPasswordPage />
    ),
  },
  {
    path: `/${RouteUrl.SignUpFlow}`,
    element: isLoggedIn ? <Navigate to={RouteUrl.App} /> : <SignUpFlowPage />,
  },
  {
    path: RouteUrl.Home,
    element: isLoggedIn ? <Navigate to={RouteUrl.App} /> : <LoginPage />,
  },
  {
    path: "*",
    element: (
      <Centered>
        <NotFoundPage />
      </Centered>
    ),
  },
];

export default routes;

const getAppRoutes = (userRole: Role) => {
  if (isFlower(userRole)) {
    return FLOWER_APP_ROUTES;
  } else if (isDistributor(userRole)) {
    return getDistributorAppRoutes(userRole);
  } else if (isInstaller(userRole)) {
    return getInstallerAppRoutes(userRole);
  } else if (isAssetOwner(userRole)) {
    return ASSET_OWNER_APP_ROUTES;
  } else if (isGuest(userRole)) {
    return [];
  } else throw Error("getAppRoutes not implemented for " + userRole);
};

const FLOWER_APP_ROUTES = [
  {
    path: RouteUrl.Dashboard,
    element: <DashboardPage />,
  },
  {
    path: RouteUrl.Distributors,
    element: <Outlet />,
    children: [
      {
        path: ":distributorId",
        element: <Outlet />,
        children: [
          {
            path: ":installerId",
            element: <Outlet />,
            children: [
              {
                path: ":assetOwnerId",
                element: <AssetOwnerPage />,
              },
              { path: "", element: <InstallerPage /> },
            ],
          },
          { path: "", element: <DistributorPage /> },
        ],
      },
      {
        path: "",
        element: <DistributorsPage />,
      },
    ],
  },
  {
    path: RouteUrl.Installers,
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <InstallerListPage />,
      },
    ],
  },
  {
    path: RouteUrl.AssetOwners,
    element: <AssetOwnerListPage />,
  },
  {
    path: RouteUrl.Assets,
    element: <AssetListPage />,
  },
  {
    path: RouteUrl.Organization,
    element: <FlowerOrganizationPage />,
  },
  {
    path: RouteUrl.Settings,
    element: <FlowerSettingsPage />,
  },
  {
    path: RouteUrl.ManualAction,
    element: <ManualActionPage />,
  },
  {
    path: RouteUrl.BillingAdjustment,
    element: <BillingAdjustmentPage />,
  },
  {
    path: "",
    element: <Navigate to={`${RouteUrl.App}/${RouteUrl.Dashboard}`} />,
  },
];

const getDistributorAppRoutes = (loggedInUserRole: Role) =>
  DISTRIBUTOR_APP_ROUTES.filter(
    (item) => loggedInUserRole >= (item.minRole ?? Role.DistributorUser),
  ).map((item) => {
    const { minRole, ...route } = item;
    return route;
  });

const DISTRIBUTOR_APP_ROUTES = [
  {
    path: RouteUrl.Dashboard,
    element: <DashboardPage />,
    minRole: Role.DistributorUser,
  },
  {
    path: RouteUrl.Installers,
    element: <Outlet />,
    children: [
      {
        path: ":installerId",
        element: <Outlet />,
        children: [
          {
            path: ":assetOwnerId",
            element: <AssetOwnerPage />,
          },
          { path: "", element: <InstallerPage /> },
        ],
      },
      {
        path: "",
        element: <InstallerListPage />,
      },
    ],
    minRole: Role.DistributorUser,
  },
  {
    path: RouteUrl.AssetOwners,
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <AssetOwnerListPage />,
      },
    ],
    minRole: Role.DistributorUser,
  },
  {
    path: RouteUrl.Organization,
    element: <DistributorOrganizationPage />,
    minRole: Role.DistributorAdmin,
  },
  {
    path: "",
    element: <Navigate to={`${RouteUrl.App}/${RouteUrl.Dashboard}`} />,
  },
];

const getInstallerAppRoutes = (loggedInUserRole: Role) =>
  INSTALLER_APP_ROUTES.filter(
    (item) => loggedInUserRole >= (item.minRole ?? Role.InstallerUser),
  ).map((item) => {
    const { minRole, ...route } = item;
    return route;
  });
const INSTALLER_APP_ROUTES = [
  {
    path: RouteUrl.Dashboard,
    element: <DashboardPage />,
    minRole: Role.InstallerUser,
  },
  {
    path: RouteUrl.AssetOwners,
    element: <Outlet />,
    children: [
      {
        path: ":assetOwnerId",
        element: <AssetOwnerPage />,
      },
      {
        path: "",
        element: <AssetOwnerListPage />,
      },
    ],
    minRole: Role.InstallerUser,
  },
  {
    path: RouteUrl.Organization,
    element: <InstallerOrganizationPage />,
    minRole: Role.InstallerAdmin,
  },
  {
    path: "",
    element: <Navigate to={`${RouteUrl.App}/${RouteUrl.Dashboard}`} />,
    minRole: Role.InstallerUser,
  },
];

const ASSET_OWNER_APP_ROUTES = [
  {
    path: RouteUrl.Dashboard,
    element: <AssetOwnerDashboardPage />,
  },
  {
    path: RouteUrl.InvoiceList,
    element: <InvoicesPage />,
  },
  {
    path: RouteUrl.Battery,
    element: <BatteryPage />,
  },
  {
    path: RouteUrl.Profile,
    element: <ProfilePage />,
  },
  {
    path: RouteUrl.ConsumptionAndRevenue,
    element: <ConsumptionAndRevenuePage />,
  },
  {
    path: "",
    element: <Navigate to={`${RouteUrl.App}/${RouteUrl.Dashboard}`} />,
  },
];
