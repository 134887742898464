import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { ConsumptionMonth } from "types/consumptionMonth.type";
import api from "../axiosInstance";
import {
  CreateAssetOwnerRequestDTO,
  GetAssetOwnerItemResponseDTO,
  GetAssetOwnerProfileResponseDTO,
  GetAssetOwnerResponseDTO,
  GetAssetOwnerWithAssetIdResponseDTO,
  GetElectricityAgreementResponseDTO,
  GetLatestAssetOwnerBasedOnAssetCreationDTO,
  GetLatestAssetOwnerForInstallerDTO,
  UpdateAssetOwnerRequestDTO,
  UpdateZavannSiteIdsRequestDTO,
} from "./assetOwner.dto";
import { GetInvoiceItemResponseDTO } from "types/invoice.type";
import { ErrorResponse } from "types/errorResponse.type";

const ENDPOINT = "/asset-owner";

export enum AssetOwnerQueryKeys {
  GetAll = "asset-owner-get-all",
  GetOne = "asset-owner-get-one",
  GetInvoices = "asset-owner-get-invoices",
  GetConsumption = "asset-owner-get-consumption",
  GetProfile = "asset-owner-get-profile",
  GetElectricityAgreement = "asset-owner-get-electricity-agreement",
  GetAssetOwnerWithAssetId = "asset-owner-get-with-asset-id",
  GetLatestBasedOnAssetCreationForFlower = "asset-owner-get-latest-for-asset-created",
  GetLatestAssetOwnerForInstaller = "asset-owner-get-latest-for-installers",
}

export const useAssetOwners = (
  filterDistributorId?: number,
  filterInstallerId?: number,
) => {
  const parameter = filterInstallerId
    ? `?installerId=${filterInstallerId}`
    : filterDistributorId
      ? `?distributorId=${filterDistributorId}`
      : "";

  return useQuery(
    [AssetOwnerQueryKeys.GetAll, parameter],
    async (): Promise<GetAssetOwnerItemResponseDTO[]> =>
      (await api.get(`${ENDPOINT}${parameter}`)).data,
  );
};

export const useAssetOwner = (id: number) =>
  useQuery(
    [AssetOwnerQueryKeys.GetOne, id],
    async (): Promise<GetAssetOwnerResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}`)).data,
    {
      enabled: !!id,
    },
  );

export const useAssetOwnerWithAssetId = (id: number) =>
  useQuery(
    [AssetOwnerQueryKeys.GetAssetOwnerWithAssetId, id],
    async (): Promise<GetAssetOwnerWithAssetIdResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/withAssetId`)).data,
    {
      enabled: !!id,
    },
  );

export const useAssetOwnerProfile = (id?: number) =>
  useQuery(
    [AssetOwnerQueryKeys.GetProfile, id],
    async (): Promise<GetAssetOwnerProfileResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/profile`)).data,
    {
      enabled: !!id,
    },
  );

export const useCreateAssetOwner = (options = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: CreateAssetOwnerRequestDTO): Promise<void> =>
      (await api.post(ENDPOINT, payload)).data,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([AssetOwnerQueryKeys.GetAll]);
        notification.info(`Asset owner created`);
      },
      onError: (error: ErrorResponse) => {
        notification.error(error.response.data.message);
      },
      ...options,
    },
  );
};

export const useUpdateAssetOwner = (id: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (
      payload: UpdateAssetOwnerRequestDTO,
    ): Promise<GetAssetOwnerResponseDTO> =>
      (await api.put(`${ENDPOINT}/${id}`, payload)).data,
    {
      onSuccess: (data: GetAssetOwnerResponseDTO) => {
        queryClient.invalidateQueries([AssetOwnerQueryKeys.GetOne, data.id]);
        notification.info(`Changes saved`);
      },
      onError: () => {
        notification.error();
      },
    },
  );
};

export const useDeleteAssetOwner = (settings = {}) => {
  const notification = useNotification();
  return useMutation(
    async (id: number): Promise<{ id: number }> =>
      await api.delete(`${ENDPOINT}/${id}`),
    {
      onError: () => {
        notification.error("Something went wrong, please try again");
      },
      ...settings,
    },
  );
};

export const useInvoices = (assetOwnerId: number) =>
  useQuery(
    [AssetOwnerQueryKeys.GetInvoices, assetOwnerId],
    async (): Promise<GetInvoiceItemResponseDTO[]> =>
      (await api.get(`${ENDPOINT}/${assetOwnerId}/invoice`)).data,
  );

export const useConsumption = (assetOwnerId: number) =>
  useQuery(
    [AssetOwnerQueryKeys.GetConsumption, assetOwnerId],
    async (): Promise<ConsumptionMonth[]> =>
      (await api.get(`${ENDPOINT}/${assetOwnerId}/consumption`)).data,
  );

export const useAssetOwnerElectricityAgreement = (id?: number) =>
  useQuery(
    [AssetOwnerQueryKeys.GetElectricityAgreement, id],
    async (): Promise<GetElectricityAgreementResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/electricity-agreement`)).data,
    {
      enabled: !!id,
    },
  );

export const useLatestBasedOnAssetCreationForFlower = () => {
  return useQuery(
    [AssetOwnerQueryKeys.GetLatestBasedOnAssetCreationForFlower],
    async (): Promise<GetLatestAssetOwnerBasedOnAssetCreationDTO[]> =>
      (await api.get(`${ENDPOINT}/dashboard/latest-with-asset`)).data,
  );
};

export const useLatestAssetOwnersForInstaller = (installerId?: number) => {
  return useQuery(
    [AssetOwnerQueryKeys.GetLatestAssetOwnerForInstaller, installerId],
    async (): Promise<GetLatestAssetOwnerForInstallerDTO[]> =>
      (await api.get(`${ENDPOINT}/${installerId}/dashboard/latest-asset-owner`))
        .data,
    {
      enabled: !!installerId,
    },
  );
};

export const useUpdateZavannSiteIds = (assetOwnerId: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (
      payload: UpdateZavannSiteIdsRequestDTO,
    ): Promise<GetAssetOwnerResponseDTO> =>
      (await api.put(`${ENDPOINT}/${assetOwnerId}/zavann-site`, payload)).data,
    {
      onSuccess: (data: GetAssetOwnerResponseDTO) => {
        queryClient.invalidateQueries([AssetOwnerQueryKeys.GetOne, data.id]);
        queryClient.invalidateQueries([
          AssetOwnerQueryKeys.GetElectricityAgreement,
          data.id,
        ]);
        queryClient.invalidateQueries([AssetOwnerQueryKeys.GetAll]);
        notification.info(`Zavann site ids updated`);
      },
      onError: () => {
        notification.error();
      },
    },
  );
};
