import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import Modal from "components/Modal";
import { DeleteDistributorConfirmation } from "./DeleteDistributorConfirmation";
import { useDeleteDistributorInvitation } from "api/distributorInvitation/distributorInvitation.endpoints";
import { useNotification } from "hooks/useNotification";
import { DistributorQueryKeys } from "api/distributor/distributor.endpoints";
import { TrashIcon } from "@heroicons/react/24/outline";

interface Props {
  distributorId: number;
  invitationId: number;
}

export const DeleteDistributorInvitation: FC<Props> = ({
  distributorId,
  invitationId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notification = useNotification();

  const [isDeleteDistributorModalOpen, setIsDistributorModalOpen] =
    useState(false);

  // api
  const { mutate: deleteInvitation, isSuccess } =
    useDeleteDistributorInvitation({
      onSuccess: () =>
        queryClient
          .invalidateQueries([DistributorQueryKeys.GetUsers, distributorId])
          .then(() => notification.info("Invitation deleted")),
    });

  // submit
  const onDeleteInvitation = () => deleteInvitation(invitationId);

  // modal close
  const onDeleteDistributorModalClose = () => {
    setIsDistributorModalOpen(false);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      onDeleteDistributorModalClose();
    }
  }, [isSuccess]);

  return (
    <>
      <button onClick={() => setIsDistributorModalOpen(true)}>
        <TrashIcon
          className="h-5 w-5 text-flowerRed-800 hover:text-flowerRed-500 cursor-pointer"
          aria-hidden="true"
        />
      </button>

      <Modal
        title={t(`distributor.Delete distributor`)}
        leftButtonAction={onDeleteDistributorModalClose}
        leftButtonText={t(`close`)}
        rightButtonActionHandler={() => onDeleteInvitation()}
        isOpen={isDeleteDistributorModalOpen}
        onClose={onDeleteDistributorModalClose}
        loadingText={t(`distributor.Deleting invitation`)}
      >
        <DeleteDistributorConfirmation />
      </Modal>
    </>
  );
};
