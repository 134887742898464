import {
  getZavannInvoiceStatusName,
  ZavannInvoiceStatus,
} from "enums/zavannInvoiceStatus.enum";
import { FC } from "react";
import { Badge, BadgeColor, BadgeSize } from "./Badge";

interface Props {
  status: ZavannInvoiceStatus;
  size?: BadgeSize;
}

export const InvoiceStatusTag: FC<Props> = ({ status, size = "small" }) => (
  <Badge
    color={getColor(status)}
    text={getZavannInvoiceStatusName(status)}
    size={size}
  />
);

const getColor = (status: ZavannInvoiceStatus): BadgeColor => {
  switch (status) {
    case ZavannInvoiceStatus.Betald:
    case ZavannInvoiceStatus.EnergiKonto:
    case ZavannInvoiceStatus.Återbetald:
      return "green";
    case ZavannInvoiceStatus.Krediterad:
    case ZavannInvoiceStatus.Överbetald:
      return "yellow";
    case ZavannInvoiceStatus.Försenad:
    case ZavannInvoiceStatus.FordranÖverlåten:
      return "red";
    case ZavannInvoiceStatus.Obetald:
    case ZavannInvoiceStatus.Delbetald:
      return "gray";
    default:
      return "gray";
  }
};
