import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FlowerHubStatisticContainer } from "./FlowerHubStatisticContainer";
import { useCurrentFlowerHubSystemManagerForAsset } from "api/flowerHubSystemManager/flowerHubSystemManager.endpoints";

interface Props {
  serialNumber: string;
  assetId: number;
  hasSystemManager: boolean;
}

export const FlowerHubStatistics: FC<Props> = ({
  serialNumber,
  assetId,
  hasSystemManager,
}) => {
  const { t } = useTranslation();

  const { data: systemManager } =
    useCurrentFlowerHubSystemManagerForAsset(assetId);

  return (
    <div className="flex flex-col items-stretch justify-center mb-8 rounded-lg border border-gray-100 bg-white shadow-sm min-h-40">
      <dl className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 lg:divide-x sm:divide-x divide-y sm:divide-y-0 lg:divide-y-0 lg:px-2 xl:px-0">
        <FlowerHubStatisticContainer
          name={t(`flowerHub.SerialNumber`)}
          data={serialNumber}
        />
        <FlowerHubStatisticContainer
          name={t(`flowerHubSystemManager.IPAddress`)}
          data={
            hasSystemManager
              ? systemManager?.ipAddress
              : t(`flowerHub.ipAddressNotSetUp`)
          }
          isPlaceholder={!hasSystemManager}
        />
        <FlowerHubStatisticContainer
          name={t(`flowerHubSystemManager.PlatformVersion`)}
          data={
            hasSystemManager
              ? systemManager?.platformVersion
              : t(`flowerHub.platformVersionNotSetUp`)
          }
          isPlaceholder={!hasSystemManager}
        />
      </dl>
    </div>
  );
};
