import { useNotification } from "hooks/useNotification";
import { useMutation, useQueryClient } from "react-query";
import api from "../axiosInstance";
import {
  CreateDistributorInvitationRequestDTO,
  CreateDistributorInvitationResponseDTO,
} from "./distributorInvitation.dto";
import { DistributorQueryKeys } from "api/distributor/distributor.endpoints";
import { SignupByCodeRequestDTO } from "../shared/signUpByCode.dto";
import { ErrorResponse } from "types/errorResponse.type";

const ENDPOINT = "/distributor-invitation";

export const useDistributorSignUp = () => {
  return useMutation(
    async (payload: SignupByCodeRequestDTO) =>
      (await api.post(`${ENDPOINT}/sign-up-by-code`, payload)).data,
  );
};

export const useInviteDistributorUser = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (
      payload: CreateDistributorInvitationRequestDTO,
    ): Promise<CreateDistributorInvitationResponseDTO> =>
      (await api.post(ENDPOINT, payload)).data,
    {
      onSuccess: (result: CreateDistributorInvitationResponseDTO) => {
        queryClient.invalidateQueries([
          DistributorQueryKeys.GetOne,
          result.distributorId,
        ]);
        queryClient.invalidateQueries([
          DistributorQueryKeys.GetUsers,
          result.distributorId,
        ]);
        notification.info(`${result.name} invited`);
      },
      onError: (error: ErrorResponse) => {
        notification.error(error.response.data.message);
      },
      ...settings,
    },
  );
};

export const useDeleteDistributorInvitation = (settings = {}) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (id: number) => await api.delete(`${ENDPOINT}/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([DistributorQueryKeys.GetOne]);
        notification.info("Invitation deleted");
      },
      onError: () => {
        notification.error();
      },
      ...settings,
    },
  );
};
